import { createSelector, createSlice } from '@reduxjs/toolkit';
import { removeExpiredTrafficFeatures, filterTrafficData } from 'components/airSpace/airspaceUtils';
import { mapBoundingBoxSelector } from 'reducers/mapSlice';
import { filterMapFeaturesByBoundingBox } from 'utils/mapUtils';

const initialState = {
	hiddenLevelFeatures: [],
	hiddenLevelWebsocketConnected: false,
	hiddenLevelConnectionError: false,
	hiddenLevelConnectionLoading: false,
	droneTagFeatures: [],
	droneTagWebsocketConnected: false,
	droneTagConnectionError: false,
	droneTagConnectionLoading: false,
	airMeshFeatures: [],
	airMeshWebsocketConnected: false,
	airMeshConnectionError: false,
	airMeshConnectionLoading: false,
	airMeshPusherInstance: null,
	pauseTrafficUpdates: false,
	airMeshAircraftTypes: [],
	airMeshProviderTypes: [],
	aircraftTypesFilterValue: [],
	providerTypesFilterValue: [],
	laancAuthsForFacility: []
};

const liveTrafficSlice = createSlice({
	name: 'liveTraffic',
	initialState,
	reducers: {
		setHLTrafficFeatures(state, action) {
			//This is filtered out in dev tools so that is doesn't clutter the feed (see configureStore.js)
			state.hiddenLevelFeatures = action.payload;
		},
		setHLWebSocketConnected(state, action) {
			state.hiddenLevelWebsocketConnected = action.payload;
		},
		setHLConnectionError(state, action) {
			state.hiddenLevelConnectionError = action.payload;
		},
		setHLConnectionLoading(state, action) {
			state.hiddenLevelConnectionLoading = action.payload;
		},
		resetHLState(state) {
			state.hiddenLevelFeatures = [];
			state.hiddenLevelWebsocketConnected = false;
			state.hiddenLevelConnectionError = false;
			state.hiddenLevelConnectionLoading = false;
		},
		setDTTrafficFeatures(state, action) {
			//This is filtered out in dev tools so that is doesn't clutter the feed (see configureStore.js)
			state.droneTagFeatures = action.payload;
		},
		setDTWebSocketConnected(state, action) {
			state.droneTagWebsocketConnected = action.payload;
		},
		setDTConnectionError(state, action) {
			state.droneTagConnectionError = action.payload;
		},
		setDTConnectionLoading(state, action) {
			state.droneTagConnectionLoading = action.payload;
		},
		resetDTState(state) {
			state.droneTagFeatures = [];
			state.droneTagWebsocketConnected = false;
			state.droneTagConnectionError = false;
			state.droneTagConnectionLoading = false;
		},
		setAMTrafficFeatures(state, action) {
			//This is filtered out in dev tools so that is doesn't clutter the feed (see configureStore.js)
			state.airMeshFeatures = action.payload;
		},
		setAMWebSocketConnected(state, action) {
			state.airMeshWebsocketConnected = action.payload;
		},
		setAMConnectionError(state, action) {
			state.airMeshConnectionError = action.payload;
		},
		setAMConnectionLoading(state, action) {
			state.airMeshConnectionLoading = action.payload;
		},
		setAMPusherInstance(state, action) {
			state.airMeshPusherInstance = action.payload;
		},
		setPauseTrafficUpdates(state, action) {
			state.pauseTrafficUpdates = action.payload;
		},
		setAirMeshAircraftTypes(state, action) {
			state.airMeshAircraftTypes = action.payload;
		},
		setAirMeshProviderTypes(state, action) {
			state.airMeshProviderTypes = action.payload;
		},
		setAircraftTypesFilterValue(state, action) {
			state.aircraftTypesFilterValue = action.payload;
		},
		setProviderTypesFilterValue(state, action) {
			state.providerTypesFilterValue = action.payload;
		},
		setLaancAuthsForFacility(state, action) {
			state.laancAuthsForFacility = action.payload;
		},
		resetAMState(state) {
			state.airMeshFeatures = [];
			state.airMeshWebsocketConnected = false;
			state.airMeshConnectionError = false;
			state.airMeshConnectionLoading = false;
			state.airMeshPusherInstance = null;
		}
	}
});

export const {
	setHLTrafficFeatures,
	setHLWebSocketConnected,
	setHLConnectionError,
	setHLConnectionLoading,
	setDTTrafficFeatures,
	setDTWebSocketConnected,
	setDTConnectionError,
	setDTConnectionLoading,
	setAMTrafficFeatures,
	setAMWebSocketConnected,
	setAMConnectionError,
	setAMConnectionLoading,
	setAMPusherInstance,
	resetHLState,
	resetAMState,
	resetDTState,
	setPauseTrafficUpdates,
	setAirMeshAircraftTypes,
	setAirMeshProviderTypes,
	setAircraftTypesFilterValue,
	setProviderTypesFilterValue,
	setLaancAuthsForFacility
} = liveTrafficSlice.actions;

export default liveTrafficSlice.reducer;

const hiddenLevelFeaturesSelector = state => state.liveTraffic.hiddenLevelFeatures;
const droneTagFeaturesSelector = state => state.liveTraffic.droneTagFeatures;
const airMeshFeaturesSelector = state => state.liveTraffic.airMeshFeatures;

export const aircraftTypesFilterValueSelector = state => state.liveTraffic.aircraftTypesFilterValue;
export const providerTypesFilterValueSelector = state => state.liveTraffic.providerTypesFilterValue;

export const trafficFeaturesSelector = createSelector(
	[
		hiddenLevelFeaturesSelector,
		droneTagFeaturesSelector,
		airMeshFeaturesSelector,
		aircraftTypesFilterValueSelector,
		providerTypesFilterValueSelector,
		mapBoundingBoxSelector
	],
	(
		hiddenLevelFeatures,
		droneTagFeatures,
		airMeshFeatures,
		aircraftTypesFilterValue,
		providerTypesFilterValue,
		mapBoundingBox
	) => {
		const expiredRemoved = removeExpiredTrafficFeatures([
			...hiddenLevelFeatures,
			...droneTagFeatures,
			...airMeshFeatures
		]);

		const filtered = filterTrafficData(
			expiredRemoved,
			aircraftTypesFilterValue,
			providerTypesFilterValue
		);
		if (mapBoundingBox) {
			const filteredByMapBounds = filterMapFeaturesByBoundingBox({
				features: filtered,
				boundingBox: mapBoundingBox
			});
			return filteredByMapBounds;
		} else {
			return filtered;
		}
	}
);

export const hiddenLevelWebsocketConnectedSelector = state =>
	state.liveTraffic.hiddenLevelWebsocketConnected;
export const hiddenLevelConnectionErrorSelector = state =>
	state.liveTraffic.hiddenLevelConnectionError;
export const hiddenLevelConnectionLoadingSelector = state =>
	state.liveTraffic.hiddenLevelConnectionLoading;

export const droneTagWebsocketConnectedSelector = state =>
	state.liveTraffic.droneTagWebsocketConnected;
export const droneTagConnectionErrorSelector = state => state.liveTraffic.droneTagConnectionError;
export const droneTagConnectionLoadingSelector = state =>
	state.liveTraffic.droneTagConnectionLoading;

export const airMeshWebsocketConnectedSelector = state =>
	state.liveTraffic.airMeshWebsocketConnected;
export const airMeshConnectionErrorSelector = state => state.liveTraffic.airMeshConnectionError;
export const airMeshConnectionLoadingSelector = state => state.liveTraffic.airMeshConnectionLoading;
export const airMeshPusherInstanceSelector = state => state.liveTraffic.airMeshPusherInstance;
export const pauseTrafficUpdatesSelector = state => state.liveTraffic.pauseTrafficUpdates;
export const airMeshAircraftTypesSelector = state => state.liveTraffic.airMeshAircraftTypes;
export const airMeshProviderTypesSelector = state => state.liveTraffic.airMeshProviderTypes;
export const laancAuthsForFacilitySelector = state => state.liveTraffic.laancAuthsForFacility;
