import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	fileLoading: false,
	preUploadFilesList: [],
	filesList: []
};

const fileUploadSlice = createSlice({
	name: 'fileUpload',
	initialState,
	reducers: {
		resetFileUploadSlice() {
			return initialState;
		},
		setFileLoading(state, action) {
			state.fileLoading = action.payload;
		},
		setPreUploadFilesList(state, action) {
			state.preUploadFilesList = action.payload;
		},
		setFilesList(state, action) {
			state.filesList = [...state.filesList, ...action.payload];
		}
	}
});

export const { resetFileUploadSlice, setFileLoading, setPreUploadFilesList, setFilesList } =
	fileUploadSlice.actions;

export default fileUploadSlice.reducer;

export const fileLoadingSelector = state => state.fileUpload.fileLoading;
export const filesListSelector = state => state.fileUpload.filesList;
export const documentsListSelector = state =>
	state.fileUpload.filesList.filter(
		f => f.document_type === 'document' || f.document_type === 'flight_log'
	); //TODO remove this once flight_logs is ready
export const mediaListSelector = state =>
	state.fileUpload.filesList.filter(f => f.document_type === 'media');
export const documentsTotalSelector = state => documentsListSelector(state).length;
export const mediaTotalSelector = state => mediaListSelector(state).length;
export const preUploadFilesListSelector = state => state.fileUpload.preUploadFilesList;
export const preUploadDocumentsSelector = state =>
	state.fileUpload.preUploadFilesList.filter(f => f.document_type === 'document');
export const preUploadMediaSelector = state =>
	state.fileUpload.preUploadFilesList.filter(f => f.document_type === 'media');
