import { t } from 'i18next';
import { orderBy, startCase } from 'lodash-es';
import {
	browserTimeZone,
	convertDroneTagDateToDayJS,
	convertHiddenLevelDateToDayJS,
	dateDisplayTimeMinutesSeconds
} from 'utils/timeHelpers';
import dayjs from 'utils/customDayJS';
import distance from '@turf/distance';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box } from '@mui/material';
import { $danger, $success } from 'constants/styles';
import { getLiveTrafficColumnsFromLS, saveLiveTrafficColumnsToLS } from 'actions/localStorage';
import { metersPerSecondToMilesPerHour, meterToFt } from 'utils/conversionHelpers';
import {
	droneTagProviderName,
	getLaancAuthFacilityColumns,
	hiddenLevelProviderName
} from 'components/airSpace/airspaceConstants';
import arrow from 'assets/icons/material_nav_arrow.png';
import airplane from 'assets/icons/airplane.png';
import airmesh from 'assets/icons/airmesh.png';
import chevron from 'assets/icons/material_chevron.png';

import { $highAltitudeColor, $lowAltitudeColor, $midAltitudeColor } from 'constants/styles/_colors';
import {
	airMeshProviderName,
	highAltitudeFeet,
	midAltitudeFeet
} from 'components/airSpace/airspaceConstants';
import formatcoords from 'formatcoords';
import centerOfMass from '@turf/center-of-mass';

export const getHLAircraftType = track => {
	if (track.properties.adsb_info) {
		return { label: t('ADSB'), value: 'adsb' }; //Airplane icon
	} else if (track.properties.asterix_info) {
		return { label: t('Asterix'), value: 'asterix' }; //Circle with arrow icon
	} else {
		return { label: t('Unknown'), value: 'unknown' }; //Circle with arrow icon
	}
};

export const getAMAircraftType = type => {
	switch (type) {
		case 'adsb': {
			return { label: t('ADSB'), value: 'adsb' };
		} //Airplane icon
		case 'asterix': {
			return { label: t('Asterix'), value: 'asterix' };
		} //Circle with arrow icon
		case 'aircraft': {
			return { label: t('Aircraft'), value: 'aircraft' };
		} //Drone icon
		case 'unknown': {
			return { label: t('Unknown'), value: 'unknown' };
		} //Circle with arrow icon
		default: {
			return { label: t('Unknown'), value: 'unknown' };
		} //Circle with arrow icon
	}
};

export const normalizeDTTelemetry = data => {
	return data.map(index => {
		const normalizeProperties = {
			time_of_applicability: convertDroneTagDateToDayJS(index.time, browserTimeZone).format(
				dateDisplayTimeMinutesSeconds
			),
			time_human_readable: convertDroneTagDateToDayJS(index.time, browserTimeZone).format(
				dateDisplayTimeMinutesSeconds
			),
			aircraft_type: 'aircraft',
			aircraft_type_label: t('Aircraft'),
			id: index.uas_id,
			provider: droneTagProviderName,
			provider_id: index.uas_id,
			altitude_msl: index.altitude ? meterToFt(index.altitude) : null,
			heading: null,
			ground_speed: null,
			lat: index.location.latitude,
			long: index.location.longitude,
			seq_number: index.seq_number,
			update: 'in-flight'
		};
		return {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: [index.location.longitude, index.location.latitude]
			},
			...normalizeProperties,
			properties: normalizeProperties
		};
	});
};

export const normalizeHLData = data => {
	return data.map(index => {
		const normalizeProperties = {
			time_of_applicability: convertHiddenLevelDateToDayJS(
				index.properties.time_of_applicability,
				browserTimeZone
			).format(dateDisplayTimeMinutesSeconds),
			time_human_readable: convertHiddenLevelDateToDayJS(
				index.properties.time_of_applicability,
				browserTimeZone
			).format(dateDisplayTimeMinutesSeconds),
			aircraft_type: getHLAircraftType(index)?.value,
			aircraft_type_label: getHLAircraftType(index)?.label,
			id: index.id,
			provider: hiddenLevelProviderName,
			provider_id: index.properties.human_id,
			altitude: index.properties?.alt_meters_agl
				? meterToFt(index.properties.alt_meters_agl)
				: 'N/A',
			heading: index.properties.heading_deg,
			ground_speed: index.properties.ground_speed_mps,
			lat: index.geometry.coordinates[1],
			long: index.geometry.coordinates[0]
		};

		return {
			type: index.type,
			geometry: index.geometry,
			...normalizeProperties,
			properties: normalizeProperties
		};
	});
};

export const getAMAltitudeDisplay = (row, value, showFt) => {
	const icon =
		row.altitude_change > 0 ? (
			<ArrowDropUpIcon fontSize='large' sx={{ color: $success }} />
		) : (
			<ArrowDropDownIcon fontSize='large' sx={{ color: $danger }} />
		);

	const showIcon = value !== 'N/A';

	return (
		value && (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				{value.toLocaleString()} {showFt ? t('ft') : ''} {showIcon && icon}
			</Box>
		)
	);
};

export const getAMGroundSpeedDisplay = (row, lowercase) => {
	const isADSB = row.aircraft_type === 'adsb';
	const lowercaseLabel = isADSB ? t('mph') : t('mps');
	const uppercaseLabel = isADSB ? t('(MPH)') : t('(MPS)');
	const label = lowercase ? lowercaseLabel : uppercaseLabel;
	const groundSpeedMPH = metersPerSecondToMilesPerHour(row.ground_speed);
	if (isADSB) {
		return `${groundSpeedMPH} ${label}`;
	} else {
		return `${row.ground_speed} ${label}`;
	}
};

export const getLiveTrafficTableColumns = isAirMesh => {
	const columnsFromLS = getLiveTrafficColumnsFromLS();

	const getWidthFromLS = (colFromLS, colName, defaultWidth) => {
		const col = colFromLS.find(index => index.field === colName);
		return col ? col.width : defaultWidth;
	};

	const airMeshColumns = [
		{
			field: 'timestamp',
			headerName: t('Timestamp'),
			width: 225,
			renderCell: params => {
				return dayjs
					.unix(params.row.timestamp / 1000) //Receiving in microseconds
					.tz(browserTimeZone)
					.format(dateDisplayTimeMinutesSeconds);
			}
		},
		{ field: 'provider_id', headerName: t('Provider ID'), width: 150 },
		{
			field: 'aircraft_type',
			headerName: t('Type'),
			width: 100,
			renderCell: parameters => {
				return getAMAircraftType(parameters.row.aircraft_type)?.label;
			}
		},
		{
			field: 'altitude_agl_ft',
			headerName: t('Altitude (FT AGL)'),
			width: 175,
			renderCell: parameters => getAMAltitudeDisplay(parameters.row, parameters.row.altitude_agl_ft)
		},
		{
			field: 'heading',
			headerName: t('Heading'),
			width: 150,
			renderCell: params => {
				return convertDegreesToCardinalDirection(params.row.heading);
			}
		},
		{
			field: 'ground_speed',
			headerName: t('Ground Speed'),
			width: 175,
			renderCell: parameters => getAMGroundSpeedDisplay(parameters.row)
		},
		{ field: 'lat', headerName: t('Latitude'), width: 150 },
		{ field: 'long', headerName: t('Longitude'), width: 150 },
		{
			field: 'altitude_rel_ft',
			headerName: t('Altitude (FT REL)'),
			width: 175,
			renderCell: parameters => {
				const icon =
					parameters.row.altitude_change > 0 ? (
						<ArrowDropUpIcon fontSize='large' sx={{ color: $success }} />
					) : (
						<ArrowDropDownIcon fontSize='large' sx={{ color: $danger }} />
					);
				const showIcon = parameters.row.altitude_rel_ft !== 'N/A';
				return (
					parameters.row.altitude_rel_ft && (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{parameters.row.altitude_rel_ft.toLocaleString()} {showIcon && icon}
						</Box>
					)
				);
			}
		},
		{
			field: 'altitude_msl_ft',
			headerName: t('Altitude (FT MSL)'),
			width: 175,
			renderCell: parameters => {
				const icon =
					parameters.row.altitude_change > 0 ? (
						<ArrowDropUpIcon fontSize='large' sx={{ color: $success }} />
					) : (
						<ArrowDropDownIcon fontSize='large' sx={{ color: $danger }} />
					);
				const showIcon = parameters.row.altitude_msl_ft !== 'N/A';
				return (
					parameters.row.altitude_msl_ft && (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{parameters.row.altitude_msl_ft.toLocaleString()} {showIcon && icon}
						</Box>
					)
				);
			}
		},
		{
			field: 'id',
			width: 350,
			headerName: t('Track ID')
		},
		{
			field: 'provider',
			headerName: t('Provider'),
			width: 200,
			renderCell: parameters => {
				return startCase(parameters.row.provider);
			}
		}
	].map(col => {
		const newWidth = getWidthFromLS(columnsFromLS, col.field, col.width);
		return {
			...col,
			width: newWidth
		};
	});

	const hiddenLevelDroneTagColumns = [
		{ field: 'time_human_readable', headerName: t('Timestamp'), width: 225 },
		{
			field: 'aircraft_type',
			headerName: t('Type'),
			width: 100,
			renderCell: parameters => {
				return getAMAircraftType(parameters.row.aircraft_type)?.label;
			}
		},
		{
			field: 'altitude',
			headerName: t('Altitude (FT AGL)'),
			width: 200,
			renderCell: parameters => {
				const icon =
					parameters.row.altitude_change > 0 ? (
						<ArrowDropUpIcon fontSize='large' sx={{ color: $success }} />
					) : (
						<ArrowDropDownIcon fontSize='large' sx={{ color: $danger }} />
					);
				const showIcon = parameters.row.altitude !== 'N/A';
				return (
					parameters.row.altitude && (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{parameters.row.altitude.toLocaleString()} {showIcon && icon}
						</Box>
					)
				);
			}
		},
		{
			field: 'heading',
			headerName: t('Heading'),
			width: 150,
			renderCell: params => {
				return convertDegreesToCardinalDirection(params.row.heading);
			}
		},
		{
			field: 'ground_speed',
			headerName: t('Ground Speed'),
			width: 175,
			renderCell: params => {
				const isADSB = params.row.aircraft_type === 'adsb';
				const groundSpeedMPH = metersPerSecondToMilesPerHour(params.row.ground_speed);
				if (isADSB) {
					return `${groundSpeedMPH} ${t('(MPH)')}`;
				} else {
					return `${params.row.ground_speed} ${t('(MPS)')}`;
				}
			}
		},
		{ field: 'lat', headerName: t('Latitude'), width: 150 },
		{ field: 'long', headerName: t('Longitude'), width: 150 },
		{
			field: 'id',
			width: 350,
			headerName: t('Track ID')
		},
		{
			field: 'provider',
			headerName: t('Provider'),
			width: 200,
			renderCell: parameters => {
				return startCase(parameters.row.provider);
			}
		},
		{ field: 'provider_id', headerName: t('Provider ID'), width: 350 },
		{
			field: 'altitude_msl',
			headerName: t('Altitude (FT MSL)'),
			width: 200,
			renderCell: parameters => {
				const icon =
					parameters.row.altitude_change > 0 ? (
						<ArrowDropUpIcon fontSize='large' sx={{ color: $success }} />
					) : (
						<ArrowDropDownIcon fontSize='large' sx={{ color: $danger }} />
					);
				const showIcon = parameters.row.altitude_msl !== 'N/A';
				return (
					parameters.row.altitude_msl && (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{parameters.row.altitude_msl} {showIcon && icon}
						</Box>
					)
				);
			}
		}
	].map(col => {
		const newWidth = getWidthFromLS(columnsFromLS, col.field, col.width);
		return {
			...col,
			width: newWidth
		};
	});

	if (isAirMesh) {
		return airMeshColumns;
	} else {
		return hiddenLevelDroneTagColumns;
	}
};

export const filterTrafficData = (data, aircraftTypeFilterValue, providerFilterValue) => {
	return data.filter(item => {
		const matchesAircraftType =
			aircraftTypeFilterValue.length === 0 || aircraftTypeFilterValue.includes(item.aircraft_type);
		const matchesProvider =
			providerFilterValue.length === 0 || providerFilterValue.includes(item.provider);
		return matchesAircraftType && matchesProvider;
	});
};

export const formatTrafficDataForTable = (data, mapPinLocation, isAirMesh) => {
	const dataWithDistanceFromPin = data.map(index => {
		return {
			...index,
			distanceFromPin: distance(index.geometry.coordinates, mapPinLocation.geometry.coordinates)
		};
	});

	const orderedData = orderBy(dataWithDistanceFromPin, ['distanceFromPin'], ['asc']);

	const columns = getLiveTrafficTableColumns(isAirMesh);

	saveLiveTrafficColumnsToLS(columns);

	return {
		columns,
		rows: orderedData
	};
};

export const createTrafficGeoJsonSource = features => {
	return {
		type: 'geojson',
		data: {
			type: 'FeatureCollection',
			features
		}
	};
};

export const formatDTDataIntoFeatures = data => {
	return data.map(index => {
		let newFeature = {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: [index.location.longitude, index.location.latitude]
			},
			properties: {
				...index,
				lat: index.location.latitude,
				long: index.location.longitude,
				velocity_x: index.velocity.x,
				velocity_y: index.velocity.y,
				velocity_z: index.velocity.z
			}
		};
		delete newFeature.properties.location;
		delete newFeature.properties.velocity;
		return newFeature;
	});
};

const removeIntFromProvider = string => {
	//Example: removes '_12' from 'airmesh_sdk_12'
	return string.replace(/_\d+/g, '');
};

export const getAltitudeInOrderOfPreference = data => {
	//1.AGL, 2. REL, 3.MSL
	if (data?.altitude_agl_ft && data?.altitude_agl_ft !== 'N/A') {
		return {
			value: data.altitude_agl_ft,
			typeLabel: 'AGL',
			type: 'altitude_agl_ft'
		};
	} else if (data?.altitude_rel_ft && data?.altitude_rel_ft !== 'N/A') {
		return {
			value: data.altitude_rel_ft,
			typeLabel: 'REL',
			type: 'altitude_rel_ft'
		};
	} else if (data?.altitude_msl_ft && data?.altitude_msl_ft !== 'N/A') {
		return {
			value: data.altitude_msl_ft,
			typeLabel: 'MSL',
			type: 'altitude_msl_ft'
		};
	}
	return null;
};

export const setAltitudeInOrderOfPreference = data => {
	//1.AGL, 2. REL, 3.MSL
	if (data?.altitude_agl_mt) {
		return data.altitude_agl_mt;
	} else if (data?.altitude_rel_mt) {
		return data.altitude_rel_mt;
	} else if (data?.altitude_msl_mt) {
		return data.altitude_msl_mt;
	}
	return null;
};

export const normalizeAMData = data => {
	return data.map(index => {
		const normalizeProperties = {
			time_of_applicability: dayjs(index.observed_timestamp)
				.tz(browserTimeZone)
				.format(dateDisplayTimeMinutesSeconds),
			timestamp: index.observed_timestamp,
			aircraft_type: index.type,
			id: index.track_id,
			provider: removeIntFromProvider(index.provider),
			provider_id: index.provider_id_human,
			altitude: meterToFt(setAltitudeInOrderOfPreference(index)),
			altitude_agl_ft: index.altitude_agl_mt ? meterToFt(index.altitude_agl_mt) : 'N/A',
			altitude_rel_ft: index.altitude_rel_mt ? meterToFt(index.altitude_rel_mt) : 'N/A',
			altitude_msl_ft: index.altitude_msl_mt ? meterToFt(index.altitude_msl_mt) : 'N/A',
			heading: index.heading_deg,
			ground_speed: index.ground_speed_mps,
			lat: index.geometry.coordinates[1],
			long: index.geometry.coordinates[0]
		};

		return {
			type: 'Feature',
			geometry: index.geometry,
			...normalizeProperties,
			properties: normalizeProperties
		};
	});
};

export const removeExpiredTrafficFeatures = features => {
	return features.filter(f => {
		const now = dayjs();
		let timeFrame = 0;
		if (f.provider === 'Drone Tag' || f.provider === 'Hidden Level') {
			timeFrame = now.subtract(60, 'second');
		} else {
			//Air Mesh
			timeFrame = now.subtract(30, 'second');
		}
		const featureDate = dayjs(f.properties.time_of_applicability);
		return featureDate.isAfter(timeFrame);
	});
};

export const convertDegreesToCardinalDirection = degrees => {
	// Normalize degrees to be within the range of 0 to 360
	let normalizedDegrees = ((degrees % 360) + 360) % 360;

	// Define cardinal and intercardinal directions with precise boundaries
	const directions = [
		{ dir: 'N', min: 0, max: 11.25 },
		{ dir: 'NNE', min: 11.25, max: 33.75 },
		{ dir: 'NE', min: 33.75, max: 56.25 },
		{ dir: 'ENE', min: 56.25, max: 78.75 },
		{ dir: 'E', min: 78.75, max: 101.25 },
		{ dir: 'ESE', min: 101.25, max: 123.75 },
		{ dir: 'SE', min: 123.75, max: 146.25 },
		{ dir: 'SSE', min: 146.25, max: 168.75 },
		{ dir: 'S', min: 168.75, max: 191.25 },
		{ dir: 'SSW', min: 191.25, max: 213.75 },
		{ dir: 'SW', min: 213.75, max: 236.25 },
		{ dir: 'WSW', min: 236.25, max: 258.75 },
		{ dir: 'W', min: 258.75, max: 281.25 },
		{ dir: 'WNW', min: 281.25, max: 303.75 },
		{ dir: 'NW', min: 303.75, max: 326.25 },
		{ dir: 'NNW', min: 326.25, max: 348.75 },
		{ dir: 'N', min: 348.75, max: 360 }
	];

	// Find the direction that matches the normalized degrees
	const direction = directions.find(d => normalizedDegrees >= d.min && normalizedDegrees < d.max);

	return direction ? direction.dir : 'N'; // Default to 'N' if no direction is found
};

export const getAltitudeColor = altitude => {
	if (altitude < midAltitudeFeet) {
		return $lowAltitudeColor; // Use the low altitude color below the mid altitude threshold
	} else if (altitude < highAltitudeFeet) {
		return $midAltitudeColor; // Use the mid altitude color between mid and high altitude thresholds
	} else {
		return $highAltitudeColor; // Use the high altitude color above the high altitude threshold
	}
};

//write a function called getTrafficIcon that return the same icon as what is defined in the icon-image property of the mapbox layer

export const getTrafficIcon = f => {
	let imgSource = airplane;
	if (f.properties.provider === airMeshProviderName) {
		imgSource = airmesh;
	} else {
		switch (f.properties.aircraft_type) {
			case 'aircraft':
				imgSource = arrow;
				break;
			case 'adsb':
				imgSource = airplane;
				break;
			case 'asterix':
				imgSource = chevron;
				break;
			default:
				imgSource = airplane;
		}
	}
	return imgSource;
};

export const convertCoordinatesFromDecimalToDMS = coordinates => {
	const [longitude, latitude] = coordinates;
	const formatted = formatcoords(longitude, latitude, true);
	return formatted.format('D:M:s X', { latLonSeparator: ' / ' });
};

export const convertAirMeshAircraftTypesToOptions = aircraftTypes => {
	return aircraftTypes.map(aircraftType => getAMAircraftType(aircraftType));
};

export const convertAirMeshProviderTypesToOptions = providerTypes => {
	return providerTypes.map(providerType => {
		return {
			label: startCase(providerType),
			value: providerType
		};
	});
};

export const createAirMeshTrailsGeoJSONSource = originalFeatures => {
	const features = [];

	originalFeatures.forEach(originalFeature => {
		const { geometry, properties } = originalFeature;
		const { previous_positions } = properties;

		let coordinates = [];

		if (previous_positions?.length > 0) {
			// Add previous positions first
			previous_positions.forEach(position => {
				coordinates.push(position.geometry.coordinates);
			});
		}

		// Finally add the current position
		coordinates.push(geometry.coordinates);

		const feature = {
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates
			}
		};

		features.push(feature);
	});

	return {
		type: 'geojson',
		data: {
			type: 'FeatureCollection',
			features: features
		}
	};
};

export const createFeatureFromLaancAuth = laancAuth => {
	const { operation, state, starts_at, stops_at, facility, id } = laancAuth;
	const { geometry } = operation;
	return {
		type: 'Feature',
		geometry,
		properties: {
			state,
			starts_at,
			stops_at,
			facility,
			id
		}
	};
};

export const createLaancAuthForFacilitySource = laancAuths => {
	const features = laancAuths.map(a => createFeatureFromLaancAuth(a));
	return {
		type: 'geojson',
		data: {
			type: 'FeatureCollection',
			features
		}
	};
};

export const createLaancAuthFeatureOnRowClick = matchingFeature => {
	let feature = createFeatureFromLaancAuth(matchingFeature);
	feature.preview_properties = getLaancAuthFacilityColumns();
	feature.properties.center = centerOfMass(matchingFeature.operation.geometry).geometry.coordinates;
	return feature;
};
