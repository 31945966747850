//Source: https://www.taniarascia.com/websockets-in-redux/
// import { DRONETAG_URL } from 'constants/environmentVariables';
import {
	setDTWebSocketConnected,
	setDTTrafficFeatures,
	setDTConnectionError,
	resetDTState,
	setDTConnectionLoading,
	pauseTrafficUpdatesSelector
} from 'reducers/liveTrafficSlice';
import { io } from 'socket.io-client';
import { normalizeDTTelemetry } from 'components/airSpace/airspaceUtils';
import { DRONETAG_PUBLIC_HOST } from 'constants/environmentVariables';
import { orderBy, uniqBy } from 'lodash-es';
import dayjs from 'utils/customDayJS';

//Note - this can't be tested locally - it needs to use the prod credentials and be deployed to a url that is whitelisted with Drone Tag in order for the connection to work

export const droneTagWebsocketMiddleware = () => parameters => next => action => {
	const { type } = action;
	const { dispatch, getState } = parameters;
	const socket = io(DRONETAG_PUBLIC_HOST, {
		autoConnect: false,
		host: DRONETAG_PUBLIC_HOST,
		path: '/socket.io/v2',
		transports: ['websocket'],
		reconnection: false
	});

	const handleWebsocketError = () => {
		dispatch(setDTConnectionLoading(false));
		dispatch(setDTWebSocketConnected(false));
		dispatch(setDTConnectionError(true));
		console.error('Failed to connect to Drone Tag');
	};
	const handleWebsocketClosed = () => {
		dispatch(resetDTState());
	};

	const handleApplyUpdates = data => {
		const pauseTrafficUpdates = pauseTrafficUpdatesSelector(getState());
		if (!pauseTrafficUpdates) {
			const normalizedTelemetry = normalizeDTTelemetry(data);
			const updatedFeatures = updateFeatures(
				normalizedTelemetry,
				getState().liveTraffic.droneTagFeatures
			);
			dispatch(setDTTrafficFeatures(updatedFeatures));
		}
	};

	switch (type) {
		case 'droneTagSocket/connect': {
			socket.connect();
			socket.on('connect', () => {
				dispatch(setDTWebSocketConnected(true));
				dispatch(setDTConnectionError(false));
				dispatch(setDTConnectionLoading(false));
			});
			socket.on('telemetry', data => {
				handleApplyUpdates(data);
			});
			socket.on('connect_error', () => {
				handleWebsocketError();
			});
			socket.on('disconnect', () => {
				handleWebsocketClosed();
			});
			break;
		}
		case 'droneTagSocket/disconnect': {
			socket.disconnect();
			break;
		}
		default: {
			break;
		}
	}

	return next(action);
};

export const droneTagSocketConnect = () => ({ type: 'droneTagSocket/connect' });
export const droneTagSocketDisconnect = () => ({ type: 'droneTagSocket/disconnect' });

export const updateFeatures = (normalizedIncomingFeatures, currentFeatures) => {
	const orderedBySeqNumber = orderBy(normalizedIncomingFeatures, 'seq_number', 'desc'); //Order with the latest seq_number first
	const uniqueFeatures = uniqBy(orderedBySeqNumber, 'id'); //Remove duplicates, keeping the latest seq_number

	const updatedFeatures = currentFeatures.map(currentFeature => {
		const featureWithNewInformation = uniqueFeatures.find(
			featureWithNewInformation => featureWithNewInformation.id === currentFeature.id
		);

		const altitudeChange = featureWithNewInformation?.properties?.altitude_msl
			? featureWithNewInformation.properties.altitude_msl - currentFeature.properties.altitude_msl
			: 0;

		if (featureWithNewInformation) {
			return {
				...featureWithNewInformation,
				altitude_change: altitudeChange,
				properties: {
					...featureWithNewInformation.properties,
					altitude_change: altitudeChange
				}
			};
		}
		return currentFeature;
	});

	const now = dayjs();
	const timeFrame = now.subtract(60, 'second');

	const updatedFeaturesOldFeaturesRemoved = updatedFeatures.filter(feature => {
		const featureDate = dayjs(feature.properties.time_of_applicability);
		return featureDate > timeFrame;
	});

	const newFeatures = uniqueFeatures.filter(
		incomingFeature =>
			!updatedFeaturesOldFeaturesRemoved.find(
				currentFeature => currentFeature.id === incomingFeature.id
			)
	);

	return [...updatedFeaturesOldFeaturesRemoved, ...newFeatures];
};
