import { configureStore } from '@reduxjs/toolkit';
import { CURRENT_ACCOUNT_ID, REFRESH_TOKEN, TOKEN } from 'constants/localStorageConstants';
import rootReducer from 'reducers';
import { LSAvailable } from 'utils/localStorageHandler';
import { sentryReduxEnhancer } from 'services/sentry';
import { Socket } from 'services/Socket';
import { hiddenLevelWebsocketMiddleware } from 'services/hiddenLevelWebsocketMiddleware';
import { droneTagWebsocketMiddleware } from 'services/droneTagWebsocketMiddleware';
import { airMeshMiddleware } from 'services/airMeshMiddleware';

export const initialStoreState = {
	userAuth: {
		token: localStorage.getItem(TOKEN),
		refreshToken: localStorage.getItem(REFRESH_TOKEN),
		currentAccountId: localStorage.getItem(CURRENT_ACCOUNT_ID)
			? Number(localStorage.getItem(CURRENT_ACCOUNT_ID))
			: null,
		amplifyError: null,
		amplifyAlert: null,
		accountExpiringDialogOpen: false,
		requestingResetCode: false,
		resetCodeSent: false
	}
};

export const setupStore = () => {
	const lsAvailable = LSAvailable(); //This app requires localStorage to be enabled and available. This is the first place in the app that localStorage is used.
	if (lsAvailable) {
		return configureStore({
			reducer: rootReducer,
			// Middleware setup to disable warnings. Ref: https://redux-toolkit.js.org/api/getDefaultMiddleware#api-reference
			middleware: getDefaultMiddleware => [
				hiddenLevelWebsocketMiddleware(new Socket()),
				droneTagWebsocketMiddleware(),
				airMeshMiddleware(),
				...getDefaultMiddleware({
					serializableCheck: false,
					immutableCheck: false
				})
			],
			devTools: {
				actionsDenylist: [
					'liveTraffic/setHLTrafficFeatures',
					'liveTraffic/setDTTrafficFeatures',
					'liveTraffic/setAMTrafficFeatures',
					'liveTraffic/setAirMeshAircraftTypes',
					'liveTraffic/setAirMeshProviderTypes'
				] //Filter out the websocket payloads for traffic
			},
			enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
			preloadedState: initialStoreState
		});
	}
};

export const reduxStore = setupStore();
