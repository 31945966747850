//Source: https://www.taniarascia.com/websockets-in-redux/

import {
	pauseTrafficUpdatesSelector,
	resetHLState,
	setHLConnectionError,
	setHLConnectionLoading,
	setHLTrafficFeatures,
	setHLWebSocketConnected
} from 'reducers/liveTrafficSlice';
import dayjs from 'utils/customDayJS';
import { normalizeHLData } from 'components/airSpace/airspaceUtils';

export const hiddenLevelWebsocketMiddleware = socket => parameters => next => action => {
	const { type, payload } = action;
	const { dispatch, getState } = parameters;

	const handleWebsocketError = () => {
		dispatch(setHLConnectionLoading(false));
		dispatch(setHLWebSocketConnected(false));
		dispatch(setHLConnectionError(true));
		console.error('Failed to connect to Hidden Level');
	};

	const handleWebsocketClosed = () => {
		dispatch(resetHLState());
	};

	const handleApplyUpdates = data => {
		const pauseTrafficUpdates = pauseTrafficUpdatesSelector(getState());
		if (!pauseTrafficUpdates) {
			const updatedFeatures = updateFeatures(
				data?.payload?.features,
				getState().liveTraffic.hiddenLevelFeatures
			);
			dispatch(setHLTrafficFeatures(updatedFeatures));
		}
	};

	switch (type) {
		case 'hiddenLevelSocket/connect': {
			socket.connect(payload);
			socket.on('open', () => {
				dispatch(setHLWebSocketConnected(true));
				dispatch(setHLConnectionError(false));
				dispatch(setHLConnectionLoading(false));
			});
			socket.on('message', res => {
				const data = JSON.parse(res.data);
				if (data.msg_type === 'track_updates') {
					handleApplyUpdates(data);
				}
			});
			socket.on('error', () => {
				handleWebsocketError();
			});
			socket.on('close', () => {
				handleWebsocketClosed();
			});
			break;
		}
		case 'hiddenLevelSocket/disconnect': {
			socket.disconnect();
			break;
		}

		default: {
			break;
		}
	}

	return next(action);
};
export const hiddenLevelSocketConnect = payload => ({ type: 'hiddenLevelSocket/connect', payload });
export const hiddenLevelSocketDisconnect = () => ({ type: 'hiddenLevelSocket/disconnect' });

export const updateFeatures = (incomingFeatures, currentFeatures) => {
	const normalizedIncomingFeatures = normalizeHLData(incomingFeatures);

	const updatedFeatures = currentFeatures.map(currentFeature => {
		const featureWithNewInformation = normalizedIncomingFeatures.find(
			featureWithNewInformation => featureWithNewInformation.id === currentFeature.id
		);

		const altitudeChange = featureWithNewInformation?.properties?.altitude
			? featureWithNewInformation.properties.altitude - currentFeature.properties.altitude
			: 0;

		if (featureWithNewInformation) {
			return {
				...featureWithNewInformation,
				altitude_change: altitudeChange,
				properties: {
					...featureWithNewInformation.properties,
					altitude_change: altitudeChange
				}
			};
		}
		return currentFeature;
	});

	const now = dayjs();
	const timeFrame = now.subtract(60, 'second');

	const updatedFeaturesOldFeaturesRemoved = updatedFeatures.filter(feature => {
		const featureDate = dayjs(feature.properties.time_of_applicability);
		return featureDate > timeFrame;
	});

	const newFeatures = normalizedIncomingFeatures.filter(
		incomingFeature =>
			!updatedFeaturesOldFeaturesRemoved.find(
				currentFeature => currentFeature.id === incomingFeature.id
			)
	);

	return [...updatedFeaturesOldFeaturesRemoved, ...newFeatures];
};
